.fieldSampleItem {
  margin-bottom: 40px;
  border: solid 2px grey;
  border-radius: 10px;
  padding: 10px;
  margin-left: 20px;
  font-size: medium;
}

.fieldSampleRow {
  display: flex;
}

.fieldSampleDataPoint {
  margin-right: 30px;
  min-width: 100px;
}

.fieldSampleValue {
  width: 70px;
}

.fieldSampleLabel {
  width: 70px;
  font-weight: bold;
  margin-right: 10px;
}

.fieldSampleContents {
  width: calc(100% - 150px);
}

.holdFromSubmit {
  margin-left: 50px;
}